/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2020-12-31 16:33:54
 * @LastEditors: hutian
 * @LastEditTime: 2021-01-06 15:11:17
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listProductProperty = params => axios({
    url: '/api/product/product/baseProperty/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addProductProperty = params => axios({
    url: '/api/product/product/baseProperty/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editProductProperty = params => axios({
    url: '/api/product/product/baseProperty/editData',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delProductProperty = params => axios({
    url:'/api/product/product/baseProperty/delData/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdProductProperty = params => axios({
    url: '/api/product/system/productProperty/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
